export const Team = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Locations</h2>
          <p>
            We have two facilities in Orange County
          </p>
        </div>
        <div id=''>

                <div className='col-md-8 mx-auto'>
                  <div className='caption'>

                    <h3>A SAFE PLACE TRANSITIONAL LIVING</h3><br />

                  </div>
                  <div className='thumbnail'>
                    <img src="./img/house2.jpeg"></img>




                  </div>
                </div>


        </div>


        <div id=''>

                <div className='col-md-4 mx-auto py-2'>
                  <div className='thumbnail'>
                    <br /><br />
                    <div className="location-block">
                      <a href="https://www.google.com/maps/place/2515+W+1st+St,+Santa+Ana,+CA+92703/@33.7457072,-117.9055785,17z/data=!3m1!4b1!4m5!3m4!1s0x80dcd860cf2025e1:0xee434c1b026d57b!8m2!3d33.745636!4d-117.9033879"><img src="./img/map.jpg"></img></a>



                    <div className='caption'>
                      <div className="addya">
                        <h4>A Safe Place Transitional Living</h4>
                        (Intake facility)<br /><u><a href="https://www.google.com/maps/place/2515+W+1st+St,+Santa+Ana,+CA+92703/@33.7457072,-117.9055785,17z/data=!3m1!4b1!4m5!3m4!1s0x80dcd860cf2025e1:0xee434c1b026d57b!8m2!3d33.745636!4d-117.9033879">2515 w 1st St. <br />
                        Santa Ana, CA 92703</a></u></div><br />

                      <div className="addya">
                            <button type="button" class="btn btn-secondary"><a href="tel:7145478477">714.547.8477</a></button> or &nbsp;
                            <button type="button" class="btn btn-secondary"><a href="tel:7146582564">714.658.2564</a></button> <br />
                          </div>

                          </div>

                    </div>


                  </div>
                </div>


        </div>

        <div id=''>

                <div className='col-md-8 mx-auto'>
                  <div className='caption'>

                    <h3>CrossRoads Transitional Living</h3><br />
                  </div>
                  <div className='thumbnail'>
                    <img src="./img/house4.jpg"></img>




                  </div>
                </div>


        </div>


        <div id=''>

          <div className='col-md-4 mx-auto'>
            <div className='thumbnail'>
              <br /><br/>
              <div className="location-block">
                <a href="https://www.google.com/maps/place/2515+W+1st+St,+Santa+Ana,+CA+92703/@33.7457072,-117.9055785,17z/data=!3m1!4b1!4m5!3m4!1s0x80dcd860cf2025e1:0xee434c1b026d57b!8m2!3d33.745636!4d-117.9033879"><img src="./img/map.jpg"></img></a>



              <div className='caption'>
                <div className="addya">
                  <h4>CrossRoads Transitional Living</h4>(Intake facility) <br />
                  <br /><u><a href="https://www.google.com/maps/place/2515+W+1st+St,+Santa+Ana,+CA+92703/@33.7457072,-117.9055785,17z/data=!3m1!4b1!4m5!3m4!1s0x80dcd860cf2025e1:0xee434c1b026d57b!8m2!3d33.745636!4d-117.9033879">2515 w 1st St. <br />
                  Santa Ana, CA 92703</a></u></div><br />

                <div className="addya">
                      <button type="button" class="btn btn-secondary"><a href="tel:7145478477">714.547.8477</a></button> or &nbsp;
                      <button type="button" class="btn btn-secondary"><a href="tel:7144660394">714.466.0394</a></button> <br />
                    </div>
</div>
              </div>


            </div>
          </div>


        </div>




      </div>
    </div>
  )
}
