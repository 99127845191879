import { useState } from 'react'

export const Contact = () => {

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className=''>
            <div className=''>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <div className="row">
                <div className="col-md-8">
                    <h1> <i className='fa fa-home'></i> Address</h1> <br />
                          <p className="">
                            <h3><b>A Safe Place/CrossRoads</b></h3>
                          <div className="addy"><a href="https://www.google.com/maps/place/2515+W+1st+St,+Santa+Ana,+CA+92703/@33.7457072,-117.9055785,17z/data=!3m1!4b1!4m5!3m4!1s0x80dcd860cf2025e1:0xee434c1b026d57b!8m2!3d33.745636!4d-117.9033879">2515 w 1st St. <br />
                          Santa Ana, CA 92703</a></div> </p>
                </div>
                <div className="">
                  <h1><i className='fa fa-phone'></i> Phone </h1>
                      <div className="col-md-2">
                          <p className="contact-butts">
                              <p>General <br /><button type="button" class="btn btn-secondary"><a href="tel:7145478477">714.547.8477</a></button></p>
                              <p>Safe Place <br /><button type="button" class="btn btn-secondary"><a href="tel:7146582564">714.658.2564</a></button></p>
                              <p>Cross Roads <br /><button type="button" class="btn btn-secondary"><a href="tel:7144660394">714.466.0394</a></button></p>
                              </p>
                            </div>
                </div>
                </div>

              </div>

            </div>
          </div>



        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022 A Safe Place Transitional
          </p>
        </div>
      </div>
    </div>
  )
}
