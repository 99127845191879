import React from "react"
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom'


export const Navigation = (props) => {

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll nav-header' href='#'>
            A Safeplace Living
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <Link
                to='about'
                activeClassName="active"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
                >ABOUT
              </Link>
            </li>
            <li>
              <Link
                to='services'
                activeClassName="active"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                >SERVICES
              </Link>
            </li>
            <li>
              <Link
                to='team'
                activeClassName="active"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                >LOCATIONS
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
